<template>
  <div class="box">
    <div class="top">
      <div class="box1">
        <div>蟹券编号 : {{ ticketNo }}</div>
        <div>蟹券密码 : {{ ticketPasswd }}</div>
      </div>
      <div class="box2">
        <div class="left">收</div>
        <div class="right">
          <div>运单号 {{ user.expressOrderNo }}</div>
          <div class="address">{{ user.receiveAddress }}</div>
          <div>{{ user.contactName }} {{ user.contactPhone }}</div>
        </div>
      </div>
      <div class="box3" v-if="list">
        <div class="ul" v-for="(item, index) in list" :key="index">
          <div class="left">
            <div class="icon"></div>
            <div class="dashed"></div>
          </div>
          <div class="right">
            <div class="title">
              {{ item.time }}
            </div>
            <div class="time">
              {{ item.context }}
            </div>
          </div>
        </div>
      </div>
      <div class="box3" style="margin-left: 25px" v-else>暂无物流信息</div>
      <div class="box4" @click="go">继续兑换</div>
    </div>
    <div class="bom">
      <img src="../assets/image/1.png" alt="" />
      <img class="right" src="../assets/image/5.png" alt="" />
    </div>
    <img class="kf" src="../assets/image/6.png" alt="" @click="kf" />
    <van-action-sheet v-model="show">
      <van-picker
        title="客服列表"
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="show = false"
      >
        <template #title>
          <div
            style="display: flex; flex-direction: column; align-items: center"
          >
            <div
              style="
                color: #333;
                font-size: 18px;
                font-weight: 600;
                margin: 5px 0;
              "
            >
              客服列表
            </div>
            <div style="color: #999; font-size: 12px">工作时间：09点~17点</div>
          </div>
        </template>
        <template #confirm><div style="color: #387aff">拨号</div></template>
      </van-picker>
    </van-action-sheet>
  </div>
</template>

<script>
import { getCrabTicket } from '../api/user'
export default {
  data() {
    return {
      columns: ['4008861797', '027-88013866', '027-88011709'],
      show: false,
      ticketNo: '',
      ticketPasswd: '',
      user: {},
      list: []
    }
  },
  created() {
    this.ticketPasswd = this.$route.query.pass
    this.ticketNo = this.$route.query.id
    this.getList()
  },
  methods: {
    kf() {
      this.show = true
    },
    onConfirm(e) {
      this.show = false
      window.location.href = 'tel:' + e
    },
    async getList() {
      const { data } = await getCrabTicket({
        ticketNo: this.ticketNo,
        ticketPasswd: this.ticketPasswd
      })
      console.log(data)
      this.user = data.data
      this.list = JSON.parse(data.data.expressOrderInfo).data
      console.log(this.list)
    },
    go() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .van-picker__toolbar {
  height: 55px;
}
.xx {
  width: 100%;
  display: flex;
  margin-top: 15px;
  .right {
    color: #dfab72;
  }
}
.iconbox {
  width: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3px;
  .icon {
    background-color: #fff;
    height: 10px;
    width: 10px;
    border-radius: 10px;
    border: 3px solid #dfab72;
  }

  .dashed {
    height: 54px;
    border-left: 1px dashed #dfab72;
  }
}
.kf {
  width: 70px;
  height: 70px;
  position: fixed;
  right: -10px;
  bottom: 20vh;
}
.box {
  border: none;
  min-height: 100vh;
  background-color: #6a201d;
  width: 100vw;
  display: flex;
  flex-direction: column;
  position: relative;
}

.top {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 84vw;
  margin-left: 8vw;
  margin-top: 30px;
  background-image: url('../assets/image/14.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  color: #dfab72;
  ::-webkit-input-placeholder {
    font-size: 14px;
    color: #dfab72;
    font-weight: 400;
    opacity: 0.6;
  }
  .box1 {
    width: 98%;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 15px 15px;
    box-sizing: border-box;
    line-height: 30px;
    border-bottom: 1px dashed #dfab72;
  }
  .box2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 98%;
    padding: 10px 0;
    box-sizing: border-box;
    border-bottom: 1px dashed #dfab72;
    .left {
      margin: 0 15px;
      width: 34px;
      height: 34px;
      border-radius: 34px;
      text-align: center;
      line-height: 34px;
      border: 1px solid #dfab72;
      font-size: 15px;
    }
    .right {
      width: 80%;
      font-size: 13px;
      line-height: 25px;
      .address {
        font-size: 15px;
        font-weight: 600;
      }
    }
  }
  .box3 {
    width: 98%;
    padding: 15px 15px 0 0;
    box-sizing: border-box;
    height: 270px;
    overflow: hidden;
    overflow-y: scroll;

    .ul {
      display: flex;
      align-items: center;
      position: relative;
      .left {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 15%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        .icon {
          background-color: #fff;
          height: 10px;
          width: 10px;
          border-radius: 10px;
          border: 3px solid #dfab72;
        }

        .dashed {
          border-left: 1px dashed #dfab72;
          height: (100% - 10px);
        }
      }

      .right {
        margin-left: 15%;
        width: 85%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .title {
          font-size: 15px;
          margin-bottom: 5px;
        }
        .time {
          font-size: 13px;
          margin-bottom: 20px;
        }
      }
    }

    .ul:last-child {
      .dashed:last-child {
        visibility: hidden;
      }
    }

    .ul:first-child {
      .dashed:first-child {
        visibility: hidden;
      }
    }
  }
  .box4 {
    line-height: 35px;
    text-align: center;
    color: #fff;
    background-color: #dfab72;
    border-radius: 35px;
    width: 80%;
    font-weight: 14px;
    margin-top: 13px;
    margin-bottom: 25px;
  }
}
.bom {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  img {
    height: 58vw;
    width: 58vw;
  }
  .right {
    width: 40vw;
    height: 48vw;
  }
}
</style>
